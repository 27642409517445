import React from 'react';
import { motion } from 'framer-motion';
import ButtonLink from '../../components/ButtonLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faCode, faNetworkWired, faChartLine, faBolt, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Section = ({ title, icon, children }) => (
  <motion.div
    className="bg-gray-800 shadow-md rounded-lg p-6"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="text-2xl font-bold text-white mb-4 flex items-center">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {title}
    </h2>
    {children}
  </motion.div>
);

const JupyterHubProject = () => {
  return (
    <div className="container mx-auto px-4 py-32 space-y-8">
      <motion.h1
        className="text-4xl font-bold text-white mb-8 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        JupyterHub on Kubernetes
      </motion.h1>

      <Section title="Project Overview" icon={faServer}>
        <p className="text-gray-300 text-lg">
          After successfully deploying my portfolio site on Kubernetes, I decided to tackle another project: setting up JupyterHub for my little cousin and friends to learn Python without worrying about installation complexities. This project showcases my journey of configuring JupyterHub on my K3s cluster and adding GPU compute capabilities for advanced data science and machine learning tasks.
        </p>
      </Section>

      <Section title="Technologies Used" icon={faCode}>
        <ul className="list-disc list-inside text-gray-300 text-lg space-y-2">
          <li>Kubernetes (K3s)</li>
          <li>JupyterHub</li>
          <li>Helm for deployment</li>
          <li>Longhorn for persistent storage</li>
          <li>Custom Docker images with data science libraries</li>
          <li>GPU passthrough with Proxmox</li>
        </ul>
      </Section>

      <Section title="Key Features" icon={faNetworkWired}>
        <ul className="list-disc list-inside text-gray-300 text-lg space-y-2">
          <li>Multi-user JupyterHub setup accessible via web browser</li>
          <li>Persistent storage for user notebooks</li>
          <li>Pre-configured data science environment with essential libraries</li>
          <li>GPU compute capabilities for advanced ML tasks</li>
          <li>Scalable architecture to handle multiple users</li>
        </ul>
      </Section>

      <Section title="Challenges and Solutions" icon={faLightbulb}>
        <p className="text-gray-300 text-lg mb-4">
          Several challenges were overcome during the development:
        </p>
        <ul className="list-disc list-inside text-gray-300 text-lg space-y-2">
          <li>Setting up persistent storage with Longhorn</li>
          <li>Configuring GPU passthrough on Proxmox</li>
          <li>Ensuring seamless integration with Kubernetes</li>
          <li>Optimizing resource allocation for multiple users</li>
          <li>Implementing secure access and user management</li>
        </ul>
      </Section>

      <div className="mt-12 text-center space-x-4">
       
        <Link to="/projects">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition-colors">
            Back to Projects
          </button>
        </Link>
      </div>
    </div>
  );
};

export default JupyterHubProject;
